import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import "../styles/legal.css"
import "../styles/homepage.css"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import Iframe from "react-iframe"

import awardshapeone from "../images/award-shape-one.png"
import awardshapetwo from "../images/award-shape-two.png"
import awardshapethree from "../images/award-shape-three.png"
import awardshapefour from "../images/award-shape-four.png"

const AFrameAnimation = ({ data }) => {
  return (
    <>
      {data.allWpPage.edges.map(({ node }) => (
        <Helmet>
          <title>
            {node.title} | Position<sup>2</sup>
          </title>
          <body className="cshowcase" />
        </Helmet>
      ))}

      <Layout>
        <section className="legal-ani">
          <div className="awards">
            <div className="container">
              <div className="container-width">
                <div className="wrap">
                  <div className="img-one image-shape">
                    <img src={awardshapeone} alt="one" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={awardshapetwo} alt="two" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={awardshapethree} alt="three" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={awardshapefour} alt="four" />
                  </div>
                  <div className="title image-shape">
                    <h3>3D Showcase</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="animtionwrapper">
          <div className="container">
            <div className="container-width">
              <div className="holder">
                <div className="each-wrapper">
                  <Iframe
                    url="https://dev-upgradep2staging.pantheonsite.io/wp-content/themes/positiontwovone/p2-3d-animatiom/index-3d.html"
                    width="450px"
                    height="450px"
                    id=""
                    frameBorder="0"
                    className=""
                    display="block"
                    position="relative"
                  />
                </div>
                <div className="each-wrapper">
                  <Iframe
                    url="https://dev-upgradep2staging.pantheonsite.io/wp-content/themes/positiontwovone/p2-3d-animatiom/index-3d-2.html"
                    width="450px"
                    height="450px"
                    id=""
                    frameBorder="0"
                    className=""
                    display="block"
                    position="relative"
                  />
                </div>
                <div className="each-wrapper">
                  <Iframe
                    url="https://dev-upgradep2staging.pantheonsite.io/wp-content/themes/positiontwovone/p2-3d-animatiom/index-3d-3.html"
                    width="450px"
                    height="450px"
                    id=""
                    frameBorder="0"
                    className=""
                    display="block"
                    position="relative"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default function AFrameAnimationBlock(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPage(filter: { id: { eq: "cG9zdDoxNzY5Mw==" } }) {
            edges {
              node {
                title
                slug
                id
              }
            }
          }
        }
      `}
      render={data => <AFrameAnimation data={data} {...props} />}
    />
  )
}
AFrameAnimation.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}
